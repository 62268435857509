import Page from './[slug]'
import { ContentfulService } from '../services/contentful';
import { CONTENT_TYPE_PAGE } from '../services/constants';

export default Page;

export async function getServerSideProps(context: any) {
	const contentfulService = new ContentfulService();
	
	try {
		const responses = await Promise.all([
			contentfulService.getEntryBySlug("home", CONTENT_TYPE_PAGE),
			contentfulService.getHeader(),
			contentfulService.getFooter(),
		]);
		if (!responses || !responses[0]) throw new Error ("Page not found");

		return {
			props: {
				page: responses[0].fields,
				header: responses[1]?.fields,
				footer: responses[2]?.fields,
			}
		}
	} catch(e) {
		return {
			notFound: true
		}
	}
}
